<template>
  <section class="detail-table">
    <common-title title="产品付款信息" />
    <div v-if="orderType === 1" class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>套系</th>
            <th>包含单品</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in data" :key="i">
            <td class="leftTd">
              <div class="series-info">
                <div class="titleName">{{ item.productName }}</div>
                <el-image :src="item.coverPictureUrl"> </el-image>
                <div>实付：¥{{ item.payAmount }}</div>
                <div v-if="item.refundAmount">已退：¥{{ item.refundAmount }}</div>
              </div>
            </td>
            <td>
              <div class="product-list">
                <div v-for="(n, ind) in item.dtoList" :key="ind" class="product-list-item">
                  <div class="product-info">
                    <div class="left">
                      <el-image :src="n.coverPictureUrl"> </el-image>
                      <div class="product-name titleName">
                        <div>{{ n.productName }}</div>
                        <div>所属品牌：{{ n.brandName ? n.brandName : '暂无品牌' }}</div>
                      </div>
                    </div>
                    <div class="other-info" style="border: none">
                      <div class="moeny">实付：¥{{ n.payAmount }}</div>
                      <div v-if="n.refundStatus === 3" class="moeny moneyColor">已退：¥{{ n.refundAmount }}</div>
                      <el-button v-if="n.refundStatus > 0" class="refundBtn" size="mini" @click="detail(n)">退款申请详情</el-button>
                    </div>
                  </div>
                  <div class="service-info">
                    <span
                      >服务单号：<el-button v-if="n.serviceNo" type="text" @click="goServiceDetail(n)">{{ n.serviceNo }}</el-button>
                      <span v-else>--</span>
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="box">
      <div v-for="(n, i) in data" :key="i" class="box-item">
        <div style="flex: 1">
          <div :style="{ borderTop: i > 0 ? 'none' : '' }">
            <div class="product-info">
              <div class="left">
                <el-image :src="n.coverPictureUrl"> </el-image>
                <div class="text">
                  <div class="product-name desc titleName">
                    {{ n.productName }}
                  </div>
                  <div class="desc">规格：{{ n.specification }}</div>
                  <div class="desc">计费单位：{{ n.priceUnit }}</div>
                </div>
              </div>
              <div style="padding-top: 10px">¥{{ n.salePrice }}&emsp;&emsp;x{{ n.number }}</div>
            </div>
          </div>
          <div v-if="n.dtoList && n.dtoList.length > 0">
            <div v-for="(item, v) in n.dtoList" :key="v" :style="{ borderTop: v > 0 ? 'none' : '' }">
              <div class="product-info">
                <div class="left">
                  <el-image :src="item.coverPictureUrl"> </el-image>
                  <div class="text">
                    <div class="product-name desc titleName">
                      {{ n.productName }}
                    </div>
                    <div class="desc">规格：{{ item.specification }}</div>
                    <div class="desc">计费单位：{{ item.priceUnit }}</div>
                    <div class="desc">所属品牌：{{ item.brandName ? item.brandName : '暂无品牌' }}</div>
                  </div>
                </div>
                <div style="padding-top: 10px">¥{{ item.salePrice }}&emsp;&emsp;x{{ n.number }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="other-info">
          <div class="moeny desc">实付：¥{{ n.payAmount }}</div>
          <div v-if="n.refundStatus === 3" class="moeny moneyColor desc">已退：¥{{ n.refundAmount }}</div>
          <el-button v-if="n.refundStatus > 0" class="refundBtn" size="mini" @click="detail(n)">退款申请详情</el-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CommonTitle from '@/components/common-title/index.vue'

export default {
  name: 'DetailTable',
  components: {
    CommonTitle,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      orderType: 0,
    }
  },
  watch: {
    type: {
      deep: true,
      handler(val) {
        console.log(val.orderType)
        this.orderType = val.orderType
        this.$forceUpdate()
      },
    },
  },
  mounted() {},
  methods: {
    detail(row) {
      console.log(row)
      this.$router.push({
        path: '/refund/detail',
        query: { orderServiceId: row.orderServiceId },
      })
    },
    goServiceDetail(row) {
      history.pushState(null, null, `/index/service/serviceOrder/serviceParticulars?id=${row.serviceId}`)
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep.title {
  font-weight: bold !important;
}
.el-image {
  margin: 4px 0;
  width: 130px;
  border-radius: 4px;
  height: 100px;
}
.detail-table {
  margin-top: 20px;
  background: #fff;
  .table-wrapper {
    padding: 0 20px;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      tr {
        th {
          padding: 10px;
          font-size: 14px;
          font-weight: 500;
          background: #f5f5f5;
          border: 1px solid #e0e0e0;
          border-top: none;
        }
      }
    }

    width: 100%;

    td {
      border: 1px solid #e0e0e0;
    }
    .leftTd {
      vertical-align: inherit;
    }
  }
  .series-info {
    padding: 20px;
  }
  .product-list {
    height: 100%;

    .product-list-item {
      display: flex;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      border-bottom: 1px solid #eaeaea;
      .product-info {
        display: flex;
        justify-content: space-between;
        flex: 1;
        padding: 20px;
        border-right: 1px solid #eaeaea;
        line-height: 36px;
        .left {
          display: flex;
          .product-name {
            padding-left: 20px;
          }
        }
      }
      .service-info {
        padding: 20px;
        width: 400px;
      }
    }
  }
}
.other-info {
  min-width: 100px;
  padding: 16px 40px;
  border-left: 1px solid #eaeaea;
  text-align: center;
}
.box {
  width: 1000px;
  padding: 20px;
  .left {
    display: flex;
    .text {
      padding-left: 20px;
    }
  }
  .box-item {
    display: flex;
    justify-content: space-between;

    border: 1px solid #eaeaea;
    .product-info {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding: 20px;
    }
    .service-info {
      padding: 20px;
      width: 300px;
    }
    .desc {
      line-height: 36px;
    }
  }
}
</style>
