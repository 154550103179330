<template>
  <section class="detail-header">
    <div class="title">
      <div class="code">支付流水号：{{ data.paymentOrderNo }}</div>
      <el-tag class="status" size="mini">{{ data.orderStateName }}</el-tag>
    </div>
    <div class="detail-info-content">
      <div class="detail-info-item">
        <div class="detail-info-item-name">支付方式：</div>
        <div class="detail-info-item-content">{{ data.channelPayModeName }}</div>
      </div>
      <div class="detail-info-item">
        <div class="detail-info-item-name">支付时间：</div>
        <div class="detail-info-item-content">{{ data.paymentTime }}</div>
      </div>
      <div class="detail-info-item">
        <div class="detail-info-item-name">订单ID：</div>
        <div class="detail-info-item-content">
          <el-button type="text" @click="goOrderDetail">{{ data.outTradeNo }}</el-button>
        </div>
      </div>

      <div class="detail-info-item">
        <div class="detail-info-item-name">所属客户：</div>
        <div class="detail-info-item-content">
          <el-button type="text">
            <span v-if="data.customerName" @click="goCustDetail">{{ data.customerName }}</span>
            <span v-else>--</span></el-button
          >
        </div>
      </div>
    </div>
    <div class="detail-info-content">
      <div class="detail-info-item">
        <div class="detail-info-item-name">订单类型：</div>
        <div class="detail-info-item-content">
          {{ data.orderTypeName }}
        </div>
      </div>
      <div class="detail-info-item">
        <div class="detail-info-item-name">实付金额：</div>
        <div class="detail-info-item-content">¥{{ data.paymentAmount }}</div>
      </div>
      <div class="detail-info-item">
        <div class="detail-info-item-name">欠款金额：</div>
        <div class="detail-info-item-content">¥{{ data.debtAmount}}</div>
      </div>
      <div class="detail-info-item">
        <div class="detail-info-item-name">已退款金额：</div>
        <div class="detail-info-item-content">¥{{ data.refundAmount }}</div>
      </div>
      <div class="detail-info-item">
        <div class="detail-info-item-name">退款后金额：</div>
        <div class="detail-info-item-content">¥{{ data.chargeBalance }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { getOrders } from '@/api/pay'

export default {
  name: 'DetailHeader',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  mounted() {
    console.log(this.data)
  },
  methods: {
    getStateName(val) {
      switch (val) {
        case 0:
          return '未支付'
        case 1:
          return '已支付成功'
        case 2:
          return '已退款'
        case 3:
          return '部分退款'
        case 4:
          return '已取消'
        case 5:
          return '已失败'
        default:
          return ''
      }
    },
    goOrderDetail() {
      let url = ''
      if (Number(this.data.orderType) === 1) {
        url = '/index/service/receivingOrder/receivingParticulars?id='
      } else {
        url = '/index/service/purchaseOrder/purchaseParticulars?id='
      }
      getOrders({
        data: { orderNo: this.data.outTradeNo },
      }).then((res) => {
        history.pushState(null, null, url + res[0].orderId)
      })
    },
    goCustDetail() {
      history.pushState(null, null, `/index/chance/clientFollowUp/particulars?id=${this.data.customerId}`)
    },
  },
}
</script>

<style scoped lang="less">
.detail-header {
  background: #fff;
  .title {
    display: flex;
    padding: 20px 20px;
    align-items: center;
    .code {
      font-size: 20px;
      color: #000;
      font-weight: 500;
    }
    .status {
      margin-left: 12px;
    }
  }
  .detail-info-content {
    padding: 0 20px;
    display: flex;
    .detail-info-item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      margin-bottom: 16px;
      .detail-info-item-name {
        color: #9a9d9e;
        font-size: 14px;
      }
      .detail-info-item-content {
        font-size: 14px;
        color: #000;
      }
    }
  }
}
</style>
