<template>
  <section v-loading="loading" class="pay-order-detail">
    <detail-header :data="data" />
    <detail-table :data="productData" :type="data" />
  </section>
</template>

<script>
import DetailHeader from './components/DetailHeader.vue';
import DetailTable from './components/DetailTable.vue';
import {
  getOrderDetail,
  getOrderProductList
  // getSplitAccountList,
  // setPaySplitAccountConfigAbout
} from '@/api/pay';

export default {
  name: 'PayDetail',
  components: {
    DetailHeader,
    DetailTable
  },
  data() {
    return {
      data: {},
      productData: [],
      loading: false
    };
  },
  created() {
    this.getDefaultConfig();
    this.getOrderProductList();
  },
  methods: {
    getDefaultConfig() {
      this.loading = true;
      getOrderDetail({ data: this.$route.query.id }).then((res) => {
        this.loading = false;
        this.data = res;
      });
      console.log(this.data);
    },
    async getOrderProductList() {
      try {
        const res = await getOrderProductList({ data: { orderNo: this.$route.query.orderNo } });
        this.productData = res;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.pay-order-detail {
  margin-top: 16px;
  padding: 16px;
}
</style>
